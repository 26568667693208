import 'bootstrap';

jQuery(function(){

  jQuery(".hover-pulse").hover(function () {
    jQuery(this).addClass("animated pulse")
  }, function () {
    jQuery(this).removeClass("animated pulse")
  });

  const templateUrl=$("#body").attr("data-tmpdir");
  jQuery(".nav-link").on("click", function () {
    var e = $(this).attr("id"),
      a = e.slice(-1),
      t = templateUrl + "img/insurance_bg_" + a + ".jpg";
      jQuery(".featured-insurance").css("background-image", "url(" + t + ")")
  });

  jQuery('.insurance-archive .item__body').matchHeight({
    byRow: false,
  })
});